/* @import url('/assets/css/dash-style.css');
@import url('/assets/css/assets.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/line-awesome/1.3.0/line-awesome/css/line-awesome.min.css'); */

#loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #18191a;
    z-index: 999;
    background-size: cover;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#loader img.img-fluid {
    height: 60px;
}

@-webkit-keyframes spin {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg)
    }
}

.fa-spin {
    animation: spin 2s infinite linear;
}

.btn i {
    font-size: 22px;
    transition: .5s all;
}

.icon-right {
    margin-left: 10px;
}

.icon-spinner:before {
    content: "\e92c";
}

[class*=" icon-"]:before,
[class^=icon-]:before {
    font-family: "forsage icons";
    font-style: normal;
    font-weight: 400;
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}